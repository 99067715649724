body {
  background: black;
  color: white;
}

img { max-width: 100%; }

.carousel .slide { background: black; }

@media (min-width: 576px) {
  .listing { max-width: 50%; }
}

@media (min-width: 992px) {
  .listing { max-width: 33.33%; }
}

.listing-images {
  background: black;

  a {
    display: block;
    height: 100%;
  }

  .carousel {
    height: 100%;

    .slide {
      img {
        max-height: 50vh;
        max-width: 100%;
        width: auto;
        margin: 0 auto;
      }
    }
  }


}

.listing-text {
  background: black;
  width: 100%;

  a {
    display: block;
    color: white;
  }

  a:hover { text-decoration: none; }
}

header.app-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background: black;
}

div.app {
  padding-top: 90px;
}
